import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import '../styles/theme/index.css'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'highlight.js/styles/github.css'
import googlePhone from 'google-libphonenumber'
import moment from 'moment'
import 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import VueHighcharts from 'vue-highcharts'
import VueHighlightJS from 'vue-highlightjs'
import Highcharts from 'highcharts'
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more'
import loadStock from 'highcharts/modules/stock'
import loadExporting from 'highcharts/modules/exporting'
import loadExportData from 'highcharts/modules/export-data'
import loadOfflineExporting from 'highcharts/modules/offline-exporting'
import loadSunburst from 'highcharts/modules/sunburst'
import loadMap from 'highcharts/modules/map'
import loadDrilldown from 'highcharts/modules/drilldown'
import * as Sentry from "@sentry/vue"
import {Integrations} from "@sentry/tracing"
import HighchartsThemes from './HighchartsTheme'
import VueMq from 'vue-mq'
import VueVisible from 'vue-visible'
import VueTheMask from 'vue-the-mask'
import VueClipboard from 'vue-clipboard2'
import Push from 'push.js'
import Bowser from 'bowser'
import Echo from 'laravel-echo'
import BusinessHours from 'vue-business-hours'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
import UUID from 'vue-uuid'
import VueWaveSurfer from 'vue-wave-surfer'
import VCalendar from 'v-calendar'
import contenteditable from 'vue-contenteditable'
import ApiV1 from './api/v1/'
import ApiV2 from './api/v2/'
import ct from "countries-and-timezones"
import * as FullStory from '@fullstory/browser'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'

//Initiate fullstory if fullstory_org_id is available
let fullstory_org_id = localStorage.getItem("fullstory_org_id")
if (fullstory_org_id) {
    FullStory.init({
        orgId: fullstory_org_id,
        // devMode: process.env.APP_ENV === 'local'
    })
}

Vue.prototype.$FullStory = FullStory

window.Bowser = Bowser
window.timezone = 'Intl' in window ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles'
window.countriesAndTimezones = ct

loadStock(Highcharts)
loadExporting(Highcharts)
loadExportData(Highcharts)
loadOfflineExporting(Highcharts)
loadSunburst(Highcharts)
loadMap(Highcharts)
loadDrilldown(Highcharts)
More(Highcharts)
Highcharts.theme = HighchartsThemes.themes.future
HighchartsNoData(Highcharts);
Highcharts.setOptions(Highcharts.theme)
Highcharts.setOptions({
    chart: {
        events: {
            load: function () {
                let chart = this
                setTimeout(() => {
                    if (chart && chart.series) {
                        $.each(chart.series, function (i, series) {
                            if (series && series.data) {
                                $.each(series.data, function (j, data) {
                                    if (data && data.yBottom && data.plotY && data.dataLabel) {
                                        if (data.yBottom - data.plotY < 15) {
                                            data.dataLabel = null
                                        }
                                    }
                                })
                            }
                        })
                    }
                }, 100)
            }
        },
        style: {
            fontFamily: ["Quicksand", "-apple-system", "system-ui", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif"]
        }
    },

    exporting: {
        buttons: {
            contextButton: {
                menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator", "downloadCSV"]
            }
        }
    }
})

window.Highcharts = Highcharts

Vue.use(VueRouter)
Vue.use(ElementUI, {locale})
Vue.use(VueHighcharts, {Highcharts})
Vue.use(wysiwyg, {
    hideModules: {
        image: true,
        table: true,
    }
})
Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xxl: Infinity
    }
})
Vue.use(VueVisible)
Vue.use(VueTheMask)
Vue.use(VueHighlightJS)
Vue.use(VueClipboard)
Vue.use(BusinessHours)
Vue.use(VueTour)
Vue.use(UUID)
Vue.use(VueWaveSurfer)
Vue.use(VCalendar)
Vue.use(contenteditable)

window.Vue = Vue

window.Push = Push

// Require `PhoneNumberFormat`.
window.PNF = googlePhone.PhoneNumberFormat

// Get an instance of `PhoneNumberUtil`.
window.phoneUtil = googlePhone.PhoneNumberUtil.getInstance()

window.getLocaleIfPhoneNumberIsFromNorthAmerica = function (phoneNumber) {
    if (!phoneNumber) {
        return false
    }

    let valid_countries = ['US', 'AG', 'AI', 'AS', 'BB', 'BM', 'BS', 'DM', 'DO', 'GD', 'GU', 'JM', 'KN', 'KY', 'LC', 'MP', 'MS', 'PR', 'SX', 'TC', 'TT', 'VC', 'VG', 'VI', 'UM', 'CA']

    try {
        for (let valid_country of valid_countries) {
            let number = window.phoneUtil.parseAndKeepRawInput(phoneNumber, valid_country)
            let isPossible = window.phoneUtil.isPossibleNumber(number)
            if (isPossible) {
                if (window.phoneUtil.isValidNumberForRegion(number, valid_country)) {
                    return valid_country
                }
            }
        }
        return false
    } catch (err) {
        return false
    }
}

window.getLocaleIfPhoneNumberIsFromGreatBritainOrAustralia = function (phoneNumber) {
    if (!phoneNumber) {
        return false
    }

    let valid_countries = ['GB', 'AU', 'IE']

    try {
        for (let valid_country of valid_countries) {
            let number = window.phoneUtil.parseAndKeepRawInput(phoneNumber, valid_country)
            let isPossible = window.phoneUtil.isPossibleNumber(number)
            if (isPossible) {
                if (window.phoneUtil.isValidNumberForRegion(number, valid_country)) {
                    return valid_country
                }
            }
        }
        return false
    } catch (err) {
        return false
    }
}

window.guessLocale = function (phoneNumber) {
    if (!phoneNumber) {
        return false
    }

    // Use substring() and indexOf() functions to remove
    // portion of string after certain character (w => wait)
    let pos = phoneNumber.indexOf('w')
    if (pos !== -1) {
        phoneNumber = phoneNumber.substring(0, pos).trim()
    }

    try {
        // handle US and CA as an special case
        let north_america_locale = window.getLocaleIfPhoneNumberIsFromNorthAmerica(phoneNumber)
        if (north_america_locale) {
            return north_america_locale
        }

        // will add + to phone number and check again
        if (!phoneNumber.includes('+')) {
            north_america_locale = window.getLocaleIfPhoneNumberIsFromNorthAmerica('+' + phoneNumber)
            if (north_america_locale) {
                return north_america_locale
            }
        }

        // will add +1 to phone number and check again
        if (!phoneNumber.includes('+')) {
            north_america_locale = window.getLocaleIfPhoneNumberIsFromNorthAmerica('+1' + phoneNumber)
            if (north_america_locale) {
                return north_america_locale
            }
        }

        // handle GB & AU as a special case
        let english_locale = window.getLocaleIfPhoneNumberIsFromGreatBritainOrAustralia(phoneNumber)
        if (english_locale) {
            return english_locale
        }

        // if we reached here then it's definitely not a US or CA number according to google-libphonenumber
        // let's check for international locales

        if (!phoneNumber.includes('+')) {
            phoneNumber = '+' + phoneNumber
        }

        let number = window.phoneUtil.parse(phoneNumber)
        let locale = window.phoneUtil.getRegionCodeForNumber(number)
        let isValid = window.phoneUtil.isValidNumber(number)

        if (!locale) {
            number = window.phoneUtil.parse(phoneNumber, 'US')
            locale = window.phoneUtil.getRegionCodeForNumber(number)
            isValid = window.phoneUtil.isValidNumber(number)
            return isValid
        }

        return locale
    } catch (err) {
        return false
    }
}

window.VueEvent = new class {
    constructor() {
        this.vue = new Vue()
    }

    fire(event, data = null) {
        this.vue.$emit(event, data)
    }

    listen(event, callback) {
        this.vue.$on(event, callback)
    }

    unlisten(event, callback) {
        this.vue.$off(event, callback)
    }

    listenOnce(event, callback) {
        this.vue.$once(event, callback)
    }
}

window._ = require('lodash')
window.FileSaver = require('file-saver')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.axios.defaults.headers.common['Requested-From'] = 'api'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')
let api_token = localStorage.getItem("api_token")

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

if (api_token) {
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'YoXKytv5WYyXqzplDxrn',
    cluster: '',
    wsHost: 'soketi.alodev.org',
    wssHost: 'soketi.alodev.org',
    forceTLS: true,
    auth: {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('api_token'),
            'Requested-From': 'api'
        },
    },
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
})

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

// global variable for APIV2 endpoints
Vue.prototype.$APIV1 = ApiV1
Vue.prototype.$APIV2 = ApiV2

window.paceOptions = {
    restartOnRequestAfter: false,
    ajax: {
        trackWebSockets: false
    }
}

momentDurationFormatSetup(moment)
window.moment = moment

function getDomainName(hostName) {
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1)
}

window.isVariableEmpty = function (value) {
    return value === undefined || value === null || value === NaN || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim().length === 0)
}

window.Sentry = Sentry

if (['production', 'development'].includes(process.env.NODE_ENV) && localStorage.getItem('app_env') != 'local') {
    Sentry.init({
        Vue,
        dsn: localStorage.getItem('sentry_dsn_public'),
        tracingOptions: {
            trackComponents: true,
        },
        attachProps: true,
        trackComponents: true,
        tracesSampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
        ignoreErrors: [
            'Non-Error promise rejection captured with value: undefined',
            'Cannot read property \'is_reseller\' of null',
            'Cannot set property \'_height\' of undefined',
            'Cannot read property \'offsetHeight\' of undefined',
            'No error message',
            '$ is not defined',
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            'Request failed with status code 504',
            'Request failed with status code 500',
            'Action timed out',
            'Network Error',
            'Expired token',
            'Network request failed',
            'Failed to fetch',
            'NetworkError',
            'Navigation cancelled from',
            'Blocked a frame with origin'
        ],

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
            new Sentry.BrowserTracing()
        ],
    })

    Sentry.configureScope((scope) => {
        scope.setTag('environment', process.env.NODE_ENV)
    })
}

// Branding :D
console.log(`%c
 █████╗ ██╗      ██████╗ ██╗    ██╗ █████╗ ██████╗ ███████╗
██╔══██╗██║     ██╔═══██╗██║    ██║██╔══██╗██╔══██╗██╔════╝
███████║██║     ██║   ██║██║ █╗ ██║███████║██████╔╝█████╗
██╔══██║██║     ██║   ██║██║███╗██║██╔══██║██╔══██╗██╔══╝
██║  ██║███████╗╚██████╔╝╚███╔███╔╝██║  ██║██║  ██║███████╗
╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
`, 'color: #00BF50')
